import { render, staticRenderFns } from "./Events.vue?vue&type=template&id=52822432&scoped=true"
import script from "./Events.vue?vue&type=script&lang=js"
export * from "./Events.vue?vue&type=script&lang=js"
import style0 from "./Events.vue?vue&type=style&index=0&id=52822432&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52822432",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CFlex: require('@chakra-ui/vue').CFlex, CSpinner: require('@chakra-ui/vue').CSpinner, CIcon: require('@chakra-ui/vue').CIcon, CText: require('@chakra-ui/vue').CText, CImage: require('@chakra-ui/vue').CImage, CBox: require('@chakra-ui/vue').CBox})
